/*==========STYLES===========*/
[data-theme="dark"] .x_color {
    color: #172E3F;
}

.x_color {
color: #4D4D4D;
}
/*==========ANIMATION===========*/
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .about-team .position-absolute h2 {
        font-size: 55px !important;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){
    .about-team .position-absolute h2 {
        font-size: 150px;
    }

}