/*==========STYLES===========*/
.servicesIntContent {
  padding: 70px 0px;
}

.servicesIntContent .preview {
  padding: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.buttonDownload {
  --width: 200px;
  --height: 43px;
  --tooltip-height: 35px;
  --tooltip-width: 120px;
  --gap-between-tooltip-to-button: calc(var(--tooltip-height) - 15px);
  --tooltip-color: #fff;
  width: var(--width);
  height: var(--height);
  background: linear-gradient(90deg,#0c0c0d,#0c0c0d),linear-gradient(to bottom right,#00d8dc,#36e4da,#16a79e);
  background-clip: padding-box,border-box;
  background-origin: padding-box,border-box;
  border: 2px solid transparent;
  position: relative;
  text-align: center;
  border-radius: 1.5em;
  transition: background 0.3s;
  padding: 10px 20px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
 }

 .buttonDownload svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  color: inherit;
}
 
.servicesIntContent .text {
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .button-wrapper,.text,.icon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
 }
 
 .servicesIntContent .text {
  top: 0
 }
 
 .servicesIntContent .text,.servicesIntContent .icon {
  transition: top 0.5s;
 }
 
 .servicesIntContent .icon {
  color: #fff;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .servicesIntContent .buttonDownload:hover .text {
  top: -100%;
 }
 
 .servicesIntContent .buttonDownload:hover .icon {
  top: 0;
 }


/* Boton Luminoso */

.glow-on-hover:before {
content: '';
position: absolute;
background: linear-gradient(45deg, #0452a0, #ff00f9, #a568fc, #7600ff, #4904aa, #029692, #6efffb, #1ef2f3, #0074ff);

top: -2px;
left:-2px;
background-size: 400%;
z-index: -1;
filter: blur(8px);
width: calc(100% + 6px);
height: calc(100% + 6px);
-webkit-animation: glowing 20s linear infinite;
animation: glowing 20s linear infinite;
opacity: 0;
transition: opacity .3s ease-in-out;
border-radius: 1.5em;
}

.glow-on-hover:active:after {
background: transparent;
}

.glow-on-hover:hover:before {
opacity: 1;
}

.glow-on-hover:after {
z-index: -1;
content: '';
position: absolute;
width: 100%;
height: 100%;
background: #111;
left: 0;
top: 0;
border-radius: 1.5em;
}

/*==========ANIMATION===========*/

@keyframes glowing {
  0% {  -ms-background-position: 0 0; -webkit-background-position: 0 0; background-position: 0 0; }
  50% {  -ms-background-position: 0 0; -webkit-background-position: 400% 0; background-position: 400% 0;}
  100% {  -ms-background-position: 0 0; -webkit-background-position: 0 0; background-position: 0 0;}
}

/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){

}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}
   