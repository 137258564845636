
.staff-augmentation .staff-augmentation .banner-staff-augmentation h2 {
    line-height: 1.1 !important;
    text-transform: uppercase;
    font-size: 43px;
}

.staff-augmentation .colum-banner {
    background: url('img/staff-augmentation/banner-1.png') no-repeat center center;
    background-size: cover;
    background-position: center;
    
    padding: 30px 28px 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    max-width: 100%;
    margin: 0;

}



.staff-augmentation h2 span {
    background: -webkit-linear-gradient(90deg, rgba(69,98,255,1), rgba(21,219,255,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;  
}

.staff-augmentation h2 span:nth-child(2) {
    background: -webkit-linear-gradient(rgba(71,18,204,1), rgba(189,0,255,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;  
}


.staff-augmentation h2 span:nth-child(3) {
    background: -webkit-linear-gradient(90deg,rgba(55,93,194,1), rgba(21,255,173,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;  
}
  
 /*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .staff-augmentation .colum-banner {
        height: 470px; /* Altura del contenedor */
    }
    .staff-augmentation .banner-staff-augmentation h2 {
        font-size: 23px !important;
        padding: 20px 10px 10px;
    }

    .banner-staff-augmentation .banner-content div {
        margin-bottom: 15px;
    }
    .colum-banner {
        margin-bottom: 25px;
        background-size: 100% auto;
        padding: 30px 25px 0;
    }

    .colum-banner h1 {
        font-size: 23px;
    }

    .banner-staff-augmentation h2,  .banner-staff-augmentation p{
        text-align: center !important;
    }
    .order-first {
        order: 1;
    }

    .order-sm-last {
        order: 2;
    }
    .colum-banner h2 {
        font-size: 20px !important;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .staff-augmentation .banner-staff-augmentation h2 {
        font-size: 30px !important;
        padding: 20px 10px 10px;
    }
    .colum-banner {
        margin-bottom: 25px;
        background-size: 100% auto;
        padding: 30px 25px 0;
        height: 340px;
    }

    .colum-banner h2 {
        font-size: 15px;
    }
}
/*Desktop*/
@media screen and (min-width:821px){
    .colum-banner {
        height: 545px;
    }
}
  

/* Estilo para móviles */

@media (max-width: 767px) {


}



