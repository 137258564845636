.entry--post-info code+code:before {
    content: "|";
    display: inline-block;
    margin: 0 7px;
}

.entry--post-info code {
    color: #677294;
    padding-bottom: 5px;
}

.post--content li, .post--content p, .post--content pre code {
    font-weight: 500;
    font-size: 19px;
    color: var(--paragraph-text-color);
}

.img-post:hover {
    box-shadow: 0 0 10px 4px #001d5766;
}

