.video-staff.video_area {
    position: relative;
    z-index: 1;
  }


.video-staff  .imgClients img {
    width: 140px;
    filter: brightness(0) invert(1);
    padding-bottom: 20px;
  }

  
.video-staff .video_content {
    position: relative;
  }

  
.video-staff  .cup {
    position: absolute;
    right: 98px;
    bottom: 40px;
  }
  
.video-staff .video_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    -webkit-box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
    box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
    height: 450px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
.video-staff .video_info h2 {
    position: absolute;
    top: 58%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 100px;
    opacity: 0.10;
    font-weight: 600;
    z-index: -1;
  }
  
.video-staff .video_icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: white;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
    box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
    text-align: center;
    line-height: 85px;
    font-size: 30px;
    position: relative;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    display: inline-block;
  }
  
.video-staff .video_icon:before.video-staff .video_icon:after {
    content: "";
    width: 100%;
    height: 100%;
    border: 0.2px solid #fff;
    border-radius: 50%;
    opacity: 0.10;
    position: absolute;
    left: 0;
  }
  
.video-staff .video_icon:before {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
  
.video-staff .video_icon:after {
    -webkit-transform: scale(3.8);
    -ms-transform: scale(3.8);
    transform: scale(3.8);
  }
  
.video-staff .video_icon i {
    color: #17c7bd;
    text-shadow: 0px 6px 25px rgba(16, 147, 140, 0.9);
    position: relative;
  }
  
.video-staff .video_icon:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
  }

  /*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){

.video-staff.video_area h2 {
    font-size: 25px;
  }

  
.video-staff .video_info {
    height: 240px;
    max-width: 100%;

}
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:821px){

}