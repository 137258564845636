/*==========STYLES===========*/

form .form-control , form .custom-select{
    height: 45px;
    padding: 10px 15px;
    font-weight: 600;
    border-radius: 20px;
}

form textarea.form-control {
    height: 150px;
}

form button {
    font-size: 17px;
    --tooltip-width: 100%;
    background: #0c0c0d;
    color: white;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;
    transition: all 0.2s;
    justify-content: center;
}
   
form button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
}
   
form button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}
   
form button:hover .svg-wrapper {
    -webkit-animation: fly-1 0.6s ease-in-out infinite alternate;
    animation: fly-1 0.6s ease-in-out infinite alternate;
}

form button:hover svg {
    transform: translateX(3.2em) rotate(45deg) scale(1.1);
}
   
form button:hover span {
    transform: translateX(5em);
    opacity: 0;
}
   
form button:active {
    transform: scale(0.99);
}

/* --- */

.bg_form .form-group{
    position: relative;
}

.bg_form .input{
    outline: none;
}

.bg_form .placeholder{
    position: absolute;
    top: 10px;
    left: 9px;
    padding: 0px 10px;
    transition: 0.3s;
    pointer-events: none;
    font-weight: 600;
}


.dark .input:focus + .placeholder, .dark .input:not(:placeholder-shown) +.placeholder{
    top: -10px;
    background-color: #111128 !important;
    color: #1CD4D2 !important;
    font-size: 13px;
}

.lightForm .input:focus + .placeholder, .lightForm .input:not(:placeholder-shown) +.placeholder{
    top: -10px;
    background-color: white !important;
    color: #666c82 !important;
    font-size: 13px;
}


/*-----STYLE DARK ----*/


.dark form .form-control, form select {
    background-color: transparent !important;
    border: 1px solid #00d8dc !important;
    color: #fff !important;
}

.dark .form-check-input.is-valid~.form-check-label {
    color: #666c82 !important;
}

.dark form .form-control {
    -webkit-text-fill-color: white !important;
}

.dark .form-control.is-invalid, .dark .was-validated .form-control:invalid {
    border: 1px solid #ffffff !important;
    background-image: none !important;
}
  
.dark .placeholder, .dark form .was-validated .form-check-input:invalid~.form-check-label, .dark form, .dark .was-validated .form-check-input:valid~.form-check-label {
    color: #fff;
}

.dark input:-internal-autofill-selected {
    background-color: transparent;
}

.btn-land {
    background: rgb(69,98,255);
    background: linear-gradient(90deg, rgba(69,98,255,1) 0%, rgba(21,219,255,1) 100%);
    color: white; /* Cambia el color del texto para asegurar la legibilidad */
    padding: 10px 20px; /* Ajusta el relleno (padding) según tus necesidades */
    border: none; /* Elimina el borde si lo prefieres */
    cursor: pointer;
}

/*==========ANIMATION===========*/
@keyframes fly-1 {
    from {
     transform: translateY(0.1em);
     -webkit-transform: translateY(0.1em);
    }
   
    to {
     transform: translateY(-0.1em);
     -webkit-transform: translateY(-0.1em);
    }
   }
/*==========RESPONSIVE===========*/
/*Mobil*/

/*Desktop*/
@media screen and (min-width:821px){

}