
.solar_systm {
  transform-style: preserve-3d;
  pointer-events: none;
  height: 95%;
  position: absolute;
  left: 0;
  right: 0;
}

.service-body .btn-services {
  color: #ffffff;
  font-size: 20px;
  padding: 8px 60px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration-line: underline !important;
}





* {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
}

 .solar_systm .planet, .planet .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}





@-webkit-keyframes planet {
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -1000px;
  }
}
@keyframes planet {
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -1000px;
  }
}

 .solar {
  
  transform: rotatex(-18deg);
  perspective: 800px;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}


.service-body {

  height: 97vh;
  width: 100%;
  overflow: hidden;
  font-family: "Jost", sans-serif;
  position: relative;
}

 .title-services {
  color: white;
  padding-top: 60px;
  text-align: center;
  font-size: 38px !important;  
  letter-spacing: 4px;
}
 .subtitle-services {
  font-size: 23px;
  color: #f39041;
  display: block;
  text-align: center;
}
 label {
  cursor: pointer;
}

 label.menu {
  color: white;
  display: block;
  position: absolute;
  cursor: pointer;
  left: 160px;
  z-index: 2;
}
 label.menu:after {
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 60px;
  content: "";
  z-index: 2;
  position: absolute;
  top: 0px;
  left: -4px;
}
 label.menu:hover h3 {
  opacity: 1;
}


 label.menu .info {
  position: relative;
  left: 15px;
}
 label.menu .info h3 {
  text-transform: uppercase;
  margin: 0;  
  letter-spacing: 2px;
}
 label.menu .info h3 {
  font-size: 14px;
  opacity: 0.4;
  margin-bottom: 4px;
}


label.menu + label.menu {
  padding: 20px 0;
}

 label.neptune.menu {
  top: calc(40% + ((30px) * 1));
}
 label.menu.venus {
  top: calc(40% + ((30px) * 2));
}
 label.menu.mercury {
  top: calc(40% + ((30px) * 3));
}




 
 label.close {
  position: absolute;
  right: 60px;
  opacity: 0;
  transition: all 0.3s 0.4s;
  z-index: 3;
  top: 65px;
}


/*
div {
  border: 2px solid red;
}*/

.solar_systm .planet {
  height: 1200px;
  width: 1200px;
  border-radius: 600px;
  transition: transform 2.8s 0.23s cubic-bezier(0.33, 0, 0, 1), opacity 2s 0.8s, box-shadow 0s 0s;
  background-size: 1140px 910px !important;
  background-repeat: no-repeat;
  top: auto;
  bottom: -820px;
}

 .planet .overlay {
  border-bottom: 1020px solid black;
  width: 1800px;
  height: 1640px;
  top: auto;
  transform: none;
  top: -240px;
  left: -303px;
  opacity: 1;
  border-radius: 100%;
  z-index: 0;
  box-shadow: 0px -190px 215px 110px black inset;
}

.solar_systm .planet_description.neptune a {
  color: #4f83e2;
}

.solar_systm .planet_description.venus a {
  color: #dacec4;
}
.solar_systm .planet_description.mercury a {
  color: #8dcdd8;
}


.service-body input[type=radio][name=planet].planet1:checked::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet1:checked + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) {
  pointer-events: all;
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(0px) translateY(0) rotatex(2deg) scaleX(1);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
          animation: planet 60s 3.9s infinite linear;
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) .planet_description h3 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) .planet_description p,
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) .planet_description a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(1) .planet .overlay {
  opacity: 1;
  
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(-2200px) translateY(0) rotatex(4deg) scale(0.79);
  opacity: 1;
  /* box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #000000 inset, 0 0px 23px 4px #000000 inset, 0 -10px 130px #000000; */
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}
.service-body input[type=radio][name=planet].planet1:checked + label + div .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

.service-body input[type=radio][name=planet].planet1:checked + label {
  opacity: 1;
}
.service-body input[type=radio][name=planet].planet1:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}
.service-body input[type=radio][name=planet].planet1:checked + label > .info h3 {
  opacity: 1;
}



.service-body input[type=radio][name=planet].planet2:checked::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) {
  pointer-events: all;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(0px) translateY(0) rotatex(2deg) scaleX(1);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
          animation: planet 60s 3.9s infinite linear;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) .planet_description h3 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) .planet_description p,
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) .planet_description a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(2) .planet .overlay {
  opacity: 1;
  
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-2200px) translateY(0) rotatex(4deg) scale(0.79);
  opacity: 1;
}
.service-body input[type=radio][name=planet].planet2:checked + label + input + label + div .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}


.service-body input[type=radio][name=planet].planet2:checked + label {
  opacity: 1;
}
.service-body input[type=radio][name=planet].planet2:checked + label:before {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}
.service-body input[type=radio][name=planet].planet2:checked + label > .info h3 {
  opacity: 1;
}



.service-body input[type=radio][name=planet].planet3:checked::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) {
  pointer-events: all;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(0px) translateY(0) rotatex(2deg) scaleX(1);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
          animation: planet 60s 3.9s infinite linear;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet_description h3 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet_description p,
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet_description a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}


.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet .overlay {
  opacity: 1;
  
}
.service-body input[type=radio][name=planet].planet3:checked + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

.service-body input[type=radio][name=planet].planet3:checked + label {
  opacity: 1;
}
.service-body input[type=radio][name=planet].planet3:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}
.service-body input[type=radio][name=planet].planet3:checked + label > .info h3 {
  opacity: 1;
}



.service-body input[type=radio][name=planet].planet4:checked::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + input::after {
  display: none;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) {
  pointer-events: all;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(0px) translateY(0) rotatex(2deg) scaleX(1);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
          animation: planet 60s 3.9s infinite linear;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet_description h3 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet_description p,
.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet_description a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}


.service-body input[type=radio][name=planet].planet4:checked + label + input + label + input + label + input + label + div .solar_systm:nth-of-type(4) .planet .overlay {
  opacity: 1;
  
}

.service-body input[type=radio][name=planet].planet4:checked + label {
  opacity: 1;
}
.service-body input[type=radio][name=planet].planet4:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}
.service-body input[type=radio][name=planet].planet4:checked + label > .info h3 {
  opacity: 1;
}





.service-body input[type=radio][name=planet] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

 .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(0px) translateY(0) rotatex(2deg) scalex(1);
  opacity: 2;
}
 .solar_systm:nth-of-type(1) .planet_description {
  opacity: 1;
}
 .solar_systm:nth-of-type(1) .planet_description p,
 .solar_systm:nth-of-type(1) .planet_description a {
  opacity: 0;
}
 .solar_systm:nth-of-type(1) .planet_description h3 {
  position: relative;
  top: -100px;
  transition: all 0.5s 0s;
}
 .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 1;
}
 .solar_systm:nth-of-type(2) .planet_description {
  opacity: 1;
}
 .solar_systm:nth-of-type(2) .planet_description p,
 .solar_systm:nth-of-type(2) .planet_description a {
  opacity: 0;
}
 .solar_systm:nth-of-type(2) .planet_description h3 {
  position: relative;
  top: -100px;
  transition: all 0.5s 0s;
}
 .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 0;
}
 .solar_systm:nth-of-type(3) .planet_description {
  opacity: 1;
}
 .solar_systm:nth-of-type(3) .planet_description p,
 .solar_systm:nth-of-type(3) .planet_description a {
  opacity: 0;
}
 .solar_systm:nth-of-type(3) .planet_description h3 {
  position: relative;
  top: -100px;
  transition: all 0.5s 0s;
}
 .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -1;
}
 .solar_systm:nth-of-type(4) .planet_description {
  opacity: 1;
}
 .solar_systm:nth-of-type(4) .planet_description p,
 .solar_systm:nth-of-type(4) .planet_description a {
  opacity: 0;
}
 .solar_systm:nth-of-type(4) .planet_description h3 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}



.solar_systm .planet.mercury {
  background: url("https://img00.deviantart.net/957c/i/2017/165/4/9/uranus_texture_map_by_jcpag2010-db7yjwb.png");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px rgba(127, 188, 171, 0.6);
}
 .solar_systm .planet.venus {
  background: url("https://nasa3d.arc.nasa.gov/shared_assets/images/ven0aaa2/ven0aaa2-copy-428-321.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #ffcb9c inset, 0 0px 23px 4px #ffcb9c inset, 0 -10px 130px #b85a07;
}
.solar_systm .planet.neptune {
  background: url("https://www.solarsystemscope.com/images/textures/full/2k_neptune.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px rgba(234, 205, 199, 0.6) inset, 0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
}
 .solar_systm .planet_description {
  width: 55%;
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0;
  z-index: 2;
  right: 0;
  color: white;
  
  transition: all 0.4s 0s;
  text-transform: uppercase;
  z-index: 1;
}

 .solar_systm .planet_description p {
  
  font-size: 14px;
  letter-spacing: 4px;
  margin: 0;
}
 .solar_systm .planet_description h3 {
  letter-spacing: 8px;
  font-size: 24px;
}

 .solar_systm .planet_description a {
  color: #fabfad;
}

 .solar_systm .planet_description p {
  line-height: 20px;
  margin-top: 14px;
  opacity: 0.9;
  margin-bottom: 10px;
}
 .solar_systm .planet_description a {
  color: #fea082;
  font-size: 15px;
  font-weight: 500;
  padding: 0 2px 5px 0px;
  border-bottom: 2px solid;
  transition: all 0.2s;
  position: relative;
  left: 0;
  z-index: 999999;
}
 .solar_systm .planet_description a:hover {
  padding: 0 10px 5px 10px;
  left: -1px;
}

@media screen and (max-width:390px){
  .service-body {
    height: 130vh;
  }

  .solar_systm .planet_description {
    width: 100%;
    padding: 0 10px;
  }

  .solar_systm .planet {
    height:100%;
    width: 100%;
    bottom: -280px;
  }

  .title-services, .subtitle-services {
    padding-right: 25px;
    padding-left: 25px;
  }
  .title-services {
    font-size: 25px !important;
  }

} 

/*Mobil*/
@media (min-width:391px) and (max-width:512px){

  .solar_systm .planet {
    height:100%;
    width: 100%;
    bottom: -290px;
  }
  
  .service-body .btn-services {
    color: #ffffff;
    font-size: 18px;
  }

  .service-body {
    height: 110vh;
  }

  label.menu .info h3 {
    font-size: 12px !important;
  }

  label.menu {
    left: 45px;
  }

  .solar_systm .planet_description h3 {
    font-size: 20px !important;
    padding: 0 15px;
  }

  .solar_systm .planet_description {
    width: 100%;
    padding: 0 10px;
  }

 

  label.menu:nth-of-type(1) {
    top: calc(31vh + ((45px) * 1));
  }
   label.menu:nth-of-type(2) {
    top: calc(31vh + ((45px) * 2));
  }
   label.menu:nth-of-type(3) {
    top: calc(31vh + ((45px) * 3));
  }

  .title-services {
    font-size: 22px !important;
    letter-spacing: 4px;
    padding: 45px 10px 0;
  }
   .subtitle-services {
    font-size: 20px;
    padding: 0 20px;
  }

  .service-body input[type=radio][name=planet] { 
    appearance: none;
  }

  
}
