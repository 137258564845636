.itemSwiper {
    border-radius: 25px
}

.itemSwiper .swiper-pagination-bullet {
    background-color: #fff;
}

.office-about .swiper-slide-active .itemSwiper .swiper-pagination {
    opacity: 1;
}

.office-about .itemSwiper .swiper-pagination {
    opacity: 0;
}