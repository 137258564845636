  .devops--practices-area .container {
    padding: 30px 60px;
    background : linear-gradient(-30deg, rgba(149, 41, 249, 1) 0%, rgba(127, 9, 249, 1) 100%);
  } 

  .devops--practices-content {
    margin: 50px 20px;
  }



/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){

  .devops--practices-area .container {
    padding: 20px;
  } 
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
  .devops--practices-area .container {
    padding: 30px 60px;
  } 
}
/*Desktop*/
@media screen and (min-width:821px){

}