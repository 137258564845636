.imgClients {
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    padding: 30px;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .imgClients img {
    width: 140px;
    
  }


  @media (max-width:1199px){
    .imgClients {
        padding: 20px 10px;
    }
    .imgClients img{
        max-width: 100%;
    }
  }

  .logos-container {
    display: grid;
    gap: 10px; 
}


/*---Animation--*/
/*---Responsive---*/
/*Mobil*/
@media screen and (max-width:512px){
    .logos-container {
        display: grip !important;
        grid-template-columns: repeat(3, 1fr); /* 3 columnas */
        grid-template-rows: repeat(3, auto);   /* 3 filas */
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:820px){
    .logos-container {
        display: grip !important;
        grid-template-columns: repeat(2, 1fr); /* 3 columnas */
        grid-template-rows: repeat(2, auto);   /* 3 filas */
    }
}
/*Desktop*/
@media screen and (min-width:821px){
    .logos-container {
        justify-content: center; /* Centrar los elementos */
        display: flex;
        flex-wrap: wrap;
    }

}