.profile--expert .list-expert li:last-child {
    font-weight: 600;
}

/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .profile--expert iframe {
        padding: 50px 0;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .profile--expert iframe {
        padding-bottom: 30px;
    }
}
/*Desktop*/
@media screen and (min-width:813px){

}