.jobsVideo-section {
    height: 60vh;
}

.jobsVideo-background {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
    transition: opacity 1s;
}

.jobsVideo-section iframe {
    height: 60vh;
}

.jobsVideo-embed {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
    height: 801.562px;
    max-width: none;
} 

/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){

}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .jobsVideo-section {
        padding: 150px 0;
    }
}
/*Desktop*/
@media screen and (min-width:821px){

}