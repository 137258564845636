.dreams  .swiper-slide {
  opacity: 0 !important;
}
.dreams  .swiper-slide-active {
  opacity: 1 !important;
}

.dreamsImg .swiper-slide {
  display: flex ;
  justify-content: center;
  align-items: flex-end;

}

.our-dreamcoders .dreamsImg {
  width: 100%;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
}

.our-dreamcoders .bg-circles {
  background: url("img/dreamcoders/Circulos.png");
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 84%;
}

.dreams h3 {
  color: white;
  font-size: 5.3rem;
  text-shadow: 
    10px 5px 0px rgba(255, 255, 255, 0.3),
    20px 10px 0px rgba(255, 255, 255, 0.2),
    30px 15px 0px rgba(255, 255, 255, 0.1);
  text-align: left;
  margin-bottom: 40px;
}




.dreams img {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, .5));
  mask-image: radial-gradient(
    120% 100% at top,
    black 85%,
    transparent 100%
);
}


/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
  .dreams .row {flex-direction: column-reverse;}
  .dreams img {
    mask-image: radial-gradient(
      120% 100% at top,
      black 85%,
      transparent 100%
    );
    height: 90%;
}
  

  .our-dreamcoders .bg-circles {
    background-size: 105%;
    margin-bottom: 50px;
  }

  .dreams h3 {
    text-align: left;
    font-size: 3.5rem;
    margin-top: 10px;
  }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:821px){

}