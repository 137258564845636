
  .bannerBenefits {
    width: 100%;
    height: 65vh;
    position:relative;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .effect-container {
    width: 100%;
    padding: 30px 0px 30px 50px;
    height: auto;
    position: relative;
    background-color: transparent;
    border: 2px solid #9DB6BA;
    border-radius: 27px;
    overflow: hidden;

  }

  .light {
    width: 200px; 
    height: 200px; 
    border-radius: 300px;
    aspect-ratio: 1;
    border: 2px solid #fff;

}
  
  .light-effect {
    width: 650px;
    height: 650px;
    background-color: #00A1A9;
    filter: blur(180px);
    border-radius: 300px;
    position: absolute;
    transition: opacity 1.2s cubic-bezier(0.16, 1, 0.3, 1);
    mix-blend-mode: soft-light;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  /*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
  .bannerBenefits h2 {font-size: 40px;}
  .effect-container {
    padding-left: 30px;
    padding-right: 20px;}
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:1821px){
  .bannerBenefits {
    width: 100%;
    height: 40vh;
    max-height: 40vh;}
}
  