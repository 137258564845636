body {
  margin: 0;
  font-family: "Jost", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color)!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Modo Light*/
:root {
  --background-color: #ffffff;
  --title-text-color: #246DFB;
  --paragraph-text-color: #666c82;
  --primary-text-color: #00d1cb;
  --button-bg: #455B72;
  --bg--contact-slider: #F1F3F4;
  --bg--slider-item: #F3F3F3;
}

/*Modo Dark*/
[data-theme="dark"] {
  --background-color: #070c11;
  --title-text-color: #00d1cb;
  --paragraph-text-color: #b9d1d5;
  --primary-text-color: #f1f1f1;
  --button-bg: #455B72;
  --bg--contact-slider: #0B141D;
  --bg--slider-item: #0E161C;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*------------*/

/* From uiverse.io by @satyamchaudharydev */
/* removing default style of button */
