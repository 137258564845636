.staff-augmentation .sec_pad {
  padding: 90px 0 60px;
}

.staff-augmentation .bg-wrap-border {
    border-bottom-right-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
}

.staff-augmentation .bg_talent_fondo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background : -moz-linear-gradient(50% 0% -90deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 2, 0.28) 27.96%,rgba(0, 2, 11, 0.44) 44.34%,rgba(0, 4, 25, 0.58) 57.73%,rgba(0, 7, 45, 0.69) 69.49%,rgba(0, 10, 71, 0.8) 80.2%,rgba(0, 15, 103, 0.9) 90.1%,rgba(0, 21, 140, 0.99) 99.28%,rgba(0, 21, 143, 1) 100%);
  background : -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 2, 0.28) 27.96%, rgba(0, 2, 11, 0.44) 44.34%, rgba(0, 4, 25, 0.58) 57.73%, rgba(0, 7, 45, 0.69) 69.49%, rgba(0, 10, 71, 0.8) 80.2%, rgba(0, 15, 103, 0.9) 90.1%, rgba(0, 21, 140, 0.99) 99.28%, rgba(0, 21, 143, 1) 100%);
  background : -webkit-gradient(linear,50% 0% ,50% 100% ,color-stop(0,rgba(0, 0, 0, 0) ),color-stop(0.2796,rgba(0, 0, 2, 0.28) ),color-stop(0.4434,rgba(0, 2, 11, 0.44) ),color-stop(0.5773,rgba(0, 4, 25, 0.58) ),color-stop(0.6949,rgba(0, 7, 45, 0.69) ),color-stop(0.802,rgba(0, 10, 71, 0.8) ),color-stop(0.901,rgba(0, 15, 103, 0.9) ),color-stop(0.9928,rgba(0, 21, 140, 0.99) ),color-stop(1,rgba(0, 21, 143, 1) ));
  background : -o-linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 2, 0.28) 27.96%, rgba(0, 2, 11, 0.44) 44.34%, rgba(0, 4, 25, 0.58) 57.73%, rgba(0, 7, 45, 0.69) 69.49%, rgba(0, 10, 71, 0.8) 80.2%, rgba(0, 15, 103, 0.9) 90.1%, rgba(0, 21, 140, 0.99) 99.28%, rgba(0, 21, 143, 1) 100%);
  background : -ms-linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 2, 0.28) 27.96%, rgba(0, 2, 11, 0.44) 44.34%, rgba(0, 4, 25, 0.58) 57.73%, rgba(0, 7, 45, 0.69) 69.49%, rgba(0, 10, 71, 0.8) 80.2%, rgba(0, 15, 103, 0.9) 90.1%, rgba(0, 21, 140, 0.99) 99.28%, rgba(0, 21, 143, 1) 100%);
  background : linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 2, 0.28) 27.96%, rgba(0, 2, 11, 0.44) 44.34%, rgba(0, 4, 25, 0.58) 57.73%, rgba(0, 7, 45, 0.69) 69.49%, rgba(0, 10, 71, 0.8) 80.2%, rgba(0, 15, 103, 0.9) 90.1%, rgba(0, 21, 140, 0.99) 99.28%, rgba(0, 21, 143, 1) 100%);
  background-size: 520px;
  -webkit-box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
  box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  
}






  .staff-augmentation .btn-folder  {
    background: rgb(69,98,255);
    background: linear-gradient(90deg, rgba(69,98,255,1) 0%, rgba(21,219,255,1) 100%);
    color: white; 
    padding: 15px 100px; 
    border: none; 
    cursor: pointer;
  }
  
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
  .staff-augmentation .btn-folder {
    padding: 12px 0; 
    text-align: center; 
    width: 100%;
    display: block;
  }

  .staff-augmentation .sec_pad {
    padding: 60px 0 40px;
  }

  .staff-augmentation .bg_talent_fondo {
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
  }


}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
  .staff-augmentation .bg_talent_fondo {
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
  }
}
/*Desktop*/
@media screen and (min-width:821px){

}


/*---- Background-Color -----*/
.bg-darkblue { 
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(27,38,99,1) 100%); 
  overflow: hidden;}

.bg_video {
  background: rgb(11,15,41);
  background: -moz-linear-gradient(180deg, rgba(11,15,41,1) 0%, rgba(0,244,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(11,15,41,1) 0%, rgba(0,244,255,1) 100%);
  background: linear-gradient(180deg, rgba(11,15,41,1) 0%, rgba(0,244,255,1) 100%);
}

.bg_folder {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(27,38,99,1) 100%);
}

