/*========== STYLES ========*/
.slider--home {
    min-height: 120vh;
    height: 120vh;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.slider--home .swiper-pagination-bullet {
    background-color: white;
    width: 20px;
    height: 10px;
    border-radius: 4px;
    cursor: pointer;
}


.slider--home h1 {
    font-size: 75px;
    font-family: Arial Black,Arial Bold,Gadget,sans-serif;
    margin: 0;
    z-index: 2;
    line-height: 1;

}

.slider--home h2 {
    z-index: 2;
    position: relative;
}

.atropos-rotate-touch {
    touch-action: auto !important;
}

.slider--home .slider-1 span {
    font-weight: 700;
    font-size: 55px;
    padding-top: 10px;

}

  
  .slider-1 img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;  
}

.slider-2 img {
    position: absolute;
    top: 50px;
    left: 1;
    right: 0;
    bottom: 0;
    margin: auto;  
    z-index: 1;
}

.slider-1 img, .slider-2 img {
  width: 850px;
  max-width: 100%;
}

.slider-2 h2 span:nth-child(1) {
  /* standard gradient background */
  background : linear-gradient(90deg, rgba(167, 171, 37, 1) 0%, rgba(49, 255, 0, 1) 100%);
  background : -moz-linear-gradient(0% 49.48% 0deg,rgba(167, 171, 37, 1) 0%,rgba(49, 255, 0, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(167, 171, 37, 1) 0%, rgba(49, 255, 0, 1) 100%);
  background : -webkit-gradient(linear,0% 49.48% ,100% 49.48% ,color-stop(0,rgba(167, 171, 37, 1) ),color-stop(1,rgba(49, 255, 0, 1) ));
  background : -o-linear-gradient(0deg, rgba(167, 171, 37, 1) 0%, rgba(49, 255, 0, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(167, 171, 37, 1) 0%, rgba(49, 255, 0, 1) 100%);
  /* clip informático */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.slider-2 h2 span:nth-child(2)
{
  background : -moz-linear-gradient(137.23% -16.26% 0deg,rgba(21, 255, 245, 1) 0%,rgba(0, 1, 212, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(21, 255, 245, 1) 0%, rgba(0, 1, 212, 1) 100%);
  background : -webkit-gradient(linear,137.23% -16.26% ,237.23% -16.26% ,color-stop(0,rgba(21, 255, 245, 1) ),color-stop(1,rgba(0, 1, 212, 1) ));
  background : -o-linear-gradient(0deg, rgba(21, 255, 245, 1) 0%, rgba(0, 1, 212, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(21, 255, 245, 1) 0%, rgba(0, 1, 212, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#15FFF5', endColorstr='#0001D4' ,GradientType=0)";
  background : linear-gradient(90deg, rgba(21, 255, 245, 1) 0%, rgba(0, 1, 212, 1) 100%);

    /* clip informático */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.slider--home h3:before {
    font-weight: 500;
}


.slider--home .html5-video-player {
    display: block;
}


/*========== ANIMATION ========*/

/*========== RESPONSIVE ========*/

/*Mobil*/
@media screen and (max-width: 512px) {
    .slider--home {
        padding: 80px 0px 0px;
        min-height: 70vh;
        overflow: hidden;
        height: 70vh !important;
    }

    .slider--home .area-slider {
        height: 70vh!important;
    }
    

    .slider--home h1 {
        font-size: 45px;
        font-family: Arial Black,Arial Bold,Gadget,sans-serif;
        font-weight: 700;
        padding-bottom: 50px;
        line-height: 0.8;
    }

    .slider--home h2 {
        font-size: 25px !important;
    }

    .slider--home .slider-1 span {
        font-size: 25px;
    }

    .slider-2 {
        margin-top: 50px;
        justify-content: start !important;
    }

    .slider--home h3 {
        font-size: 23px !important;
    }



}

/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

    .slider--home {
        min-height: 70vh;
        overflow: hidden;
        height: 70vh !important;
    }
    .slider-2 {
        margin-top: 100px;
    }

    .slider--home .area-slider {
        height: 70vh!important;
    }


}
/*Desktop*/
@media screen and (min-width:821px){
    .slider-2 {
        align-items: center ;
        justify-content: center;
    }

    .slider--home .area-slider {
        height: 100vh!important;
    }
}


