.border-radius-25 {
    border-radius: 25px !important;
}

.staff-augmentation .profile--slider .swiper-pagination-bullet {
    display: inline-block;
    width: 40px;
    height: 10px;
    margin: 0 9px;
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    
}

.staff-augmentation .profile--slider .swiper-slide {
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.folder-child {
    background: url('img/staff-augmentation/folder-interna.webp') no-repeat center center;
    background-size: 100% 100%;
    padding: 0 25px;
    display: flex;
    align-items: flex-end;
}

.py-3.mb-3.folder-child:hover {
    transform: scale(0.98);
    filter: drop-shadow(2px 4px 6px black);
}

.folder-child p {
    display: inline-block;
    padding-bottom: 3px;
    position: relative;
    transition: all .1s linear;
}

  .folder-child p:before {
    bottom: 0;
    content: "";
    height: 1.5px;
    position: absolute;
    transition: width .2s linear;
    background: #ffffff;
    left: 0;
    right: auto;
    width: 100%;
}

.staff-augmentation .profile--slider {
    background: url('img/staff-augmentation/folder-father.webp') no-repeat center center;
    background-size: 100% 100%;
    padding: 0 50px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-content: stretch;
}


/*Mobil*/
@media screen and (max-width:512px){
.staff-augmentation .profile--slider h2 {
    font-size: 30px;
    width: 75% !important;
    height: 90%;
}

.profile--slider .row {
    padding: 20px 40px 0;
}

.staff-augmentation .profile--slider {
    padding: 40px 20px 30px;
   
}
.folder-child {
    background-size: 95% 100%;
}



}
/*Tablet*/
@media (min-width: 513px) and (max-width:1020px){
    .staff-augmentation .profile--slider h2 {
        font-size: 30px;
        width: 75% !important;
    }
    .staff-augmentation .profile--slider {
        padding: 50px 20px 20px;
       
    }
    .folder-child {
        background-size: 95% 100%;
    }
}
/*Desktop*/
@media screen and (min-width:1021px){
    .staff-augmentation .profile--slider {
        height: 600px;
        width: 100%;
    }

    .staff-augmentation .profile--slider h2 {
        font-size: 60px;
    }


}



.folder-child {
    height: 96px;
}



