/*========== STYLES ========*/
  .banner_services h1 span {
    letter-spacing: 0;
    animation: textServices 15s infinite alternate;
}


/*========== ANIMATION ========*/
@-webkit-keyframes textServices {
    0%, 100%{
        letter-spacing: 0px;
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px)
    }
    70%{
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        letter-spacing: 15px;
    }
}


@keyframes textServices {
    0%, 100%{
        letter-spacing: 0px;
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px)
    }
    70%{
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        letter-spacing: 15px;
    }
}


/*========== RESPONSIVE ========*/
/*Mobil*/
@media screen and (max-width:512px){

}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}