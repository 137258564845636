.staff-augmentation .banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}



/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    
}
/*Tablet*/
@media (min-width: 513px) and (max-width:1024px){
    .staff-augmentation .banner {
        min-height: 50vh !important;
        padding: 20px 0 40px;
    }
}
/*Desktop*/
@media screen and (min-width:1025px){
    .staff-augmentation .banner {
        min-height: 100vh;
        padding: 20px 0 40px;
    }
}
