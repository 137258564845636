/*==========STYLES===========*/
.office-about {
    background-color: var(--bg--contact-slider)!important; transition: background-color 250ms ease-in-out;
}

.office-about .officeSwiper {
    width: 100%;
    height: 100%;
    margin-bottom: 80px;
    overflow: inherit;
}

.office-about  .swiper-slide {
    text-align: left;

  }
  
  .office-about  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


.office--item .office-content {
    background-color: var(--bg--slider-item);
    transition: background-color 250ms ease-in-out;
    box-shadow: 1px 1px 12px 5px rgb(1 1 0 / 10%);
    padding: 0 0 20px 0;
    position: relative;
    border-radius: 25px;
    border-bottom: 5px solid #afafaf00;
}

.swiper-slide-active .office--item .office-content {
    border-bottom: 5px solid #A35DE9;
}

.office-about .btn-nav-swiper .swiper-button-next, 
.office-about .btn-nav-swiper .swiper-button-prev{
    top: 114% !important;
}

/*==========ANIMATION===========*/
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){

}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}
