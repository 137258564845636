/*==========STYLES===========*/
.slider-services {
  overflow: hidden;
}
  .slider-services .swiper {
    width: 100%;
    height: 100%;
    z-index: 0;
    color: white;
    margin-bottom: 50px;
    overflow: inherit;
  }
   
  .slider-services .swiper-slide {
    text-align: center;
    height: auto;
    font-size: 18px;
    
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .slider-services .swiper-pagination {
    right: 0 !important;
  }
   
  .slider-services .swiper-pagination-bullet {
    opacity: 1;
    background: #555;
  }
  
  .slider-services .swiper-pagination-bullet-active {
    background: #00C1DA;
    width: 10px;
    height: 10px;
  }


   .services-items {
    border-radius: 40px;
    background: #242334;
    box-shadow: 20px 20px 1px 4px rgb(36 35 52 / 40%);
  }

.software {color: #18FEFF !important;}
.consulting {color: #39B14A !important;}
.app-mobile {color: #F3901E !important;}
.database {color: #662D8D !important;}

.service_item_area {
  z-index: 0;
  padding: 60px 0px;   
}



.services-items .content-item {
  padding: 50px 60px;
  flex-direction: column;
  justify-content: center;
}

.services-items .content-item li  {
  text-align: left;
}

.services-items img {
  width: 60%;
}
   
   
/*==========ANIMATION===========*/
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .services_content {
      padding: 30px 30px !important;
    }
    .banner_services.banner h1 {
      font-size: 40px;}
  
    .service_item_area {
      padding: 30px 0;
    }

    .services-items img {
      width: 45%;
    }

    .services-items {
      margin-left: 0 !important;
      margin-right: 0 !important;
      box-shadow: none;
    }

    .services-items .content-item {
      padding: 0;
    }
    .services-items .content-item p, .content-item li  {
      font-size: 16px;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .services_content {
      padding: 30px 30px !important;
    }
    .services-items img { width: 90% !important;}

    .services-items .content-item {
      padding: 0;
    }
}
/*Desktop*/
@media screen and (min-width:813px){
  .services-items img { width: 90% !important;}
}