.about-values {
    background: url("img/about/fortalezas.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

#Values .swiper {
    width: 100%;
    height: 100%;
    margin: 80px auto;
    overflow: inherit;
  }

  .swiper-values .swiper-slide {
    width: 400px !important;
  }
  
  #Values .swiper-slide {
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-radius: 25px;
    height: 330px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  #Values .swiper-slide {
    padding: 40px;
  }
  
  @media (max-width: 991px) {
    #Values .swiper-slide {
      padding: 20px;
    }
  }
  
  
  #Values .swiper-slide:nth-child(1) {
    background: linear-gradient(0deg, rgba(255,42,250,1) 0%, rgba(103,36,229,1) 100%);
  }
    
    #Values .swiper-slide:nth-child(2) {
    background: linear-gradient(0deg, rgba(85,255,252,1) 0%, rgba(1,36,229,1) 100%);
  }
    
    #Values .swiper-slide:nth-child(3) {
      height: 390px;
      background: linear-gradient(0deg, rgba(0,239,180,1) 0%, rgba(0,188,252,1) 100%);
  }
  
  .swiper-values .swiper-slide h2 {
    opacity: 0.8;
    font-size: 25px !important;
    color: #fff;
    cursor: default;
  }
  
  .swiper-values .swiper-slide:nth-child(1).swiper-slide-thumb-active h2 { color: rgba(180,0,255,1); }
  .swiper-values .swiper-slide:nth-child(2).swiper-slide-thumb-active h2 { color: rgba(0,255,255,1); }
  .swiper-values .swiper-slide:nth-child(3).swiper-slide-thumb-active h2 { color: rgba(58,180,235,1); }
  
  .swiper-values .swiper-slide-thumb-active h2 {
    opacity: 1;
    font-size: 25px;
  }

  @media (min-width: 351px) and (max-width:512px){
    .our_values p {
      font-size: 17px;
  }



  }
  