/*==================== redesSociales ================================*/
.social_icon {
  border-top: 0.5px solid #e9ecf36b;
}
  
  .social_iconos a {
    width: 38px;
    height: 38px;
    font-size: 20px;
    line-height: 55px;
    text-align: center;
    transition: all 0.2s linear;
    display: inline-block;

  }

  .social_iconos a:hover {
    color: #32A6DA;
    font-size: 25px;
    background: transparent;
  }

  .social_icon .btn_wsp:hover {
    background: transparent;
    color: #00bb2d !important;
  }

  .social_icon .btn_wsp i {

    font-size: 1.5rem!important;
  }
  
  .btn_wsp i:hover {
    color: #00bb2d;
    font-size: 1.8rem!important;
  }

  .fa-whatsapp:before {
    content: "\f232";
  }

  /*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .social_iconos {
        padding-bottom: 15px;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .social_iconos {
        padding-bottom: 15px;
    }
}
/*Desktop*/
@media screen and (min-width:813px){

}