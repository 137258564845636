

.post--content h2, .post--content h3, .post--content h4{
  color: #32A6DA;
}


.post--content h3 {
  font-size: 22px;
  font-weight: 500;
}

.post--area .breadcrumb {
  display: flex;
  padding: 2rem 0 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
  .post--content h1 {
    font-size: 30px;
  }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:821px){
  .post--img {
    height: 520px;
    overflow: hidden;
  }
}

