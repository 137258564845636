/*---Style---*/
.header_area {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 20;
  top: 0;
  box-shadow: 0px -2px 8px 0px var(--paragraph-text-color);
  -webkit-box-shadow: 0px -2px 8px 0px var(--paragraph-text-color);
}



.dropdown-item:focus, .dropdown-item:hover, header .dropdown-item:active {
  background-color: var(--background-color)!important;
}

.btn-dark-mode {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--paragraph-text-color);
  font-size: larger;
  display: flex;
  padding: 0;
}

header a {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: .2px;
    cursor: pointer;
}

.nav-item>.nav-link {
    padding: 0;
    color: var(--paragraph-text-color);
    transition: color .3s ease 0s;
    position: relative;
}

header .btn_get {
    border: 1px solid #677391;
    padding: 0 23px;
    background: transparent;
    min-width: 120px;
    text-align: center;
    color: var(--paragraph-text-color);
    overflow: hidden;
    display: inline-block;
    z-index: 1;
    border-radius: 45px;
    line-height: 40px;
}

header .btn_get:hover {
    border-color: var(--button-bg);
    background: var(--button-bg);
    color: #fff;
}

header .nav-item .nav-link:before {
    background: #677391;
}
.nav-item>.nav-link:before {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    transition: all .2s linear;
}

header .dropdown-item:active{
    color: #677391;
    background-color: #fff !important;
}

header .dropdown-item.active {
    color: #f85c8c;
    text-decoration: none;
    background-color: var(--background-color);
    font-weight: 700;
}

/*========== MENU HAMBURGUESA ========*/

.menu_toggle {
    width: 22px;
    height: 22px;
    position: relative;
    cursor: pointer;
    display: block;
  }
  
  .menu_toggle .hamburger {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    
  }
  
  .menu_toggle .hamburger span {
    width: 0%;
    height: 2px;
    position: relative;
    top: 0;
    left: 0;
    margin: 4px 0;
    display: block;
    border-radius: 3px;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
  }
  
  .menu_toggle .hamburger span:nth-child(1) {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
  
  .menu_toggle .hamburger span:nth-child(2) {
    -webkit-transition-delay: .125s;
    -o-transition-delay: .125s;
    transition-delay: .125s;
  }
  
  .menu_toggle .hamburger span:nth-child(3) {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
  }
  
  .menu_toggle .hamburger-cross {
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
  }

  .navbar-collapse {
    transition: height 0.3s ease, opacity 0.3s ease;

  }
  
  
  .menu_toggle .hamburger-cross span {
    display: block;
    background: #677391;
    border-radius: 3px;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
  }
  
  .menu_toggle .hamburger-cross span:nth-child(1) {
    height: 100%;
    width: 2px;
    position: absolute;
    top: 0;
    left: 10px;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
  }
  
  .menu_toggle .hamburger-cross span:nth-child(2) {
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 10px;
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
  }
  
  .collapsed .menu_toggle .hamburger span {
    width: 100%;
  }
  
  .collapsed .menu_toggle .hamburger span:nth-child(1) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
  }
  
  .collapsed .menu_toggle .hamburger span:nth-child(2) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
  }
  
  .collapsed .menu_toggle .hamburger span:nth-child(3) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
  }
  
  .collapsed .menu_toggle .hamburger-cross span:nth-child(1) {
    height: 0%;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
  
  .collapsed .menu_toggle .hamburger-cross span:nth-child(2) {
    width: 0%;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
  }

header .menu_toggle .hamburger span {
    background: #677391;
}

.collapsed .menu_toggle .hamburger span:first-child {
    transition-delay: .3s;
}

header .dropdown-menu {
  border: 0;
}




/*========== RESPONSIVE ========*/
/*Mobil*/
@media screen and (max-width:512px){
    header  .nav-item  {
      padding: 10px 0;
    }

    header .navbar-nav {
      margin-top: 15px;
    }

    header .lang-dark .nav-item + .nav-item {
      margin-left: 30px !important;
    }

    ul.navbar-nav.pl-4.lang-dark {
      flex-direction: row !important;
    }

    header {
      padding: 20px 0 !important;
    }

    .submenu .dropdown-toggle::after {
      position: absolute !important;
      right: 0 !important;
      top: 50% !important;
   }

   .menu .submenu.nav-item .dropdown-menu {
    display: block;
   }

    .dropdown-menu a {
      font-weight: 500;
    }

    .navbar-nav.menu  > li {
      border-bottom: 0.1rem solid #d2d2d2;
    }

    .submenu  > li {
      border-bottom: none;
    }

    #MenuContent .nav-item+.nav-item {
      margin-left: 0;
      
    }

    header {
      padding: 10px 25px!important;
    }

}

/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

  #MenuContent .nav-item {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .navbar-nav {
    margin-top: 10px;
  }

  header {
    padding: 20px 0!important;
  }
}
/*Desktop*/
@media screen and (min-width:822px){
    header.navbar-brand, .menu_toggle {
        margin-bottom: 0;
        transition: all .2s linear;
    }

    header .dropdown-menu {
        border: 0px;
        width: 50%;
    }

    #MenuContent .nav-item+.nav-item {
      margin-left: 20px;
    }

    .menu .submenu.nav-item:hover .dropdown-menu {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    } 

    .menu > .nav-item.submenu .dropdown-menu {
      transform: translateY(20px);
      transition: all 0.3s ease-in;
      opacity: 0;
      visibility: hidden;
      display: block;
  }

  .menu > .nav-item.submenu .dropdown-menu {
    margin: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    left: 0px;
    min-width: 240px;
    padding: 15px 0;
    background-color: var(--background-color);
    box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  }

  .menu > .nav-item.submenu .dropdown-menu .nav-item{
    margin: 0 !important;
    font-weight: 500;
    
    padding: 5px 0 5px 15px;
  }

  .submenu .nav-item>.nav-link {
    font-weight: 500;
    font-size: 16px;
  }

  .submenu.nav-item .nav-item:hover >.nav-link {
    font-weight: 600;
  }
}


.navbar_fixed .header_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--background-color);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}