.banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}



/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .banner h1 {
        font-size: 30px;
        line-height: 1.1;
    }
    .banner .f--size-20 {
        font-size: 18px;
        padding: 0 10px !important;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}
