.scrum--events-tab .nav-item .nav-link.active h3{
    color: #fff;
    font-weight: 600;
}


.scrum--events-tab .nav-item .nav-link {
    border-color: #F7AC40;
    padding: 20px 0px;
    border-radius: 4px;
    border: 2px solid #F7AC40;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  
  .scrum--events-tab .nav-item .nav-link .icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 30px;
  
    color: #fff;
    margin-bottom: 15px;
  }
  
  .scrum--events-tab .nav-item .nav-link h3 {
    color: #F7AC40;
    white-space: pre-line;
    margin-bottom: 0;
  }
  
  .scrum--events-tab .nav-item .nav-link.active {
    border: 0px solid transparent;
    background : linear-gradient(90deg, rgba(244, 207, 87, 1) 0%, rgba(252, 115, 70, 1) 99.09%);
  }

  .scrum--events-tab .nav-item {
    width: 25%;
    padding: 15px;
    margin: 0;
    text-align: center;
}

.scrum--events-tab {
    border: 0;
    margin-bottom: 50px !important;
    align-items: center;
}

.scrum--artifacts-area {
  background: linear-gradient(90deg,#f4cf57,#fc7346 99.09%);
  color: #fff;
}


/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
  .scrum--events-tab .nav-item {
    width: 50%;
  }

  .banner--img img {
    width: 200px;
  }

  .scrum--events-tab .nav-item .nav-link {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 25px;
}

}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
  .scrum--events-tab .nav-item {
    width: 50%;
}

}
/*Desktop*/
@media screen and (min-width:821px){

}