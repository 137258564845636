/*==========STYLES===========*/
.about-leaders {
    background: url("img/about/leaders-about.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

.line-color-about {
    display: none;
}

.header-img {
    padding-top: 100%;
    width: 100%;
    max-height: 650px;
    margin-top: 0;
    margin-bottom: 10px;
}

#leaders h1 , #leaders .seo_title p {
    margin-top: 0;

}

/*==========ANIMATION===========*/
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    #leaders .header-img {
        min-width: 100vw;
        padding-top: 100%;
        right: -50%;
        margin-top: 30px;
        transform: translateX(-50%);
        position: relative;
        min-height: 300px;
    }

    #leaders h1 {
        font-size: 35px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.5;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}