
.jobs--tab .media {
    border-radius: 20px;
    padding: 20px;
    height: 340px;
    transform: translateY(10px);
    transition: all 0.3s linear;
  }
  
  .jobs--tab .media-body {
    border: 2px solid #fff ;
    padding: 35px 20px;
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }

.bg_color1 { background: linear-gradient(0deg, rgba(0, 134, 94, 1) 0%, rgba(0, 220, 94, 1) 100%);}
.bg_color2 { background: linear-gradient(0deg, rgba(104, 0, 212, 1) 0%, rgba(110, 85, 193, 1) 100%);}
.bg_color3 { background: linear-gradient(0deg, rgba(0, 162, 170, 1) 0%, rgba(30, 206, 255, 1) 100%);}
.bg_color4 { background: linear-gradient(0deg, rgba(1, 111, 255, 1) 0%, rgba(78, 123, 223, 1) 100%);}
.bg_color5 { background: linear-gradient(0deg, rgba(254, 100, 34, 1) 0%, rgba(255, 159, 1, 1) 100%);}
.bg_color6 { background: linear-gradient(0deg, rgba(174, 69, 127, 1) 0%, rgba(235, 33, 241, 1) 100%);}

.color1, .bg_color1.profile--form .input:focus + .placeholder, .bg_color1.profile--form .input:not(:placeholder-shown) +.placeholder { color: rgba(0, 134, 94, 1)!important;}
.color2, .bg_color2.profile--form .input:focus + .placeholder, .bg_color2.profile--form .input:not(:placeholder-shown) +.placeholder { color: rgba(104, 0, 212, 1)!important;}
.color3, .bg_color3.profile--form .input:focus + .placeholder, .bg_color3.profile--form .input:not(:placeholder-shown) +.placeholder { color: rgba(0, 162, 170, 1) !important;}
.color4, .bg_color4.profile--form .input:focus + .placeholder, .bg_color4.profile--form .input:not(:placeholder-shown) +.placeholder { color: rgba(1, 111, 255, 1) !important;}
.color5, .bg_color5.profile--form .input:focus + .placeholder, .bg_color5.profile--form .input:not(:placeholder-shown) +.placeholder { color: rgba(254, 100, 34, 1)!important;}
.color6, .bg_color6.profile--form .input:focus + .placeholder, .bg_color6.profile--form .input:not(:placeholder-shown) +.placeholder { color: rgba(174, 69, 127, 1)!important;}


.border--color1 { border-color: rgba(0, 134, 94, 1)!important;}
.border--color2 { border-color: rgba(104, 0, 212, 1)!important;}
.border--color3 { border-color: rgba(0, 162, 170, 1) !important;}
.border--color4 { border-color: rgba(1, 111, 255, 1) !important;}
.border--color5 { border-color: rgba(254, 100, 34, 1)!important;}
.border--color6 { border-color: rgba(174, 69, 127, 1)!important;}


  /*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .jobs--tab .media {
        height: 260px;
      }
      .jobs--tab h2.text-center.m_color.f--size-40.f_600.mb-4 {
        font-size: 35px;
      }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .jobs--tab .media {
        height: 250px;
      }
}
/*Desktop*/
@media screen and (min-width:813px){

}