.profile--form {
    overflow: hidden;
}

.profile--form .inputfile {
    cursor: pointer !important;
    opacity: 0;
    overflow: auto;
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0;
    left: 0;
}

.profile--form .btn-file span {
    cursor: pointer;
}

.btn_form {
    background: #fff!important;
    font-size: 15px;
    border: 0 solid #fff!important;
    padding: 10px 15px;
    transition: all .3s linear;
    display: inline-block;
    border-radius: 20px;
    
}

.profile--form .form-control, .profile--form select {
    background-color: transparent;
    border: 1px solid #7aef60!important;
    color: #fff;
    font-weight: 600 !important;
}


.profile--form .form-control.is-invalid, .profile--form .was-validated .form-control:invalid {
    border-color: #ced4da!important;
    background-image: none!important;
}
.profile--form .form-control.is-invalid, .profile--form .was-validated .form-control:invalid {
    border-color: #fff!important;
}

.profile--form input::placeholder {
    color: #ffffff9c;
}

.profile--form form .form-control {
    -webkit-text-fill-color: white !important;
}

.profile--form form button {
    font-size: 17px;
    border: 1.5px solid #fff;
    --tooltip-width: 100%;
    background: #ffffff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;
    transition: all 0.2s;
    justify-content: center;
}

/*--------*/

.profile--form .form-group{
    position: relative;
}

.profile--form .input{
    outline: none;
}

.profile--form .placeholder{
    position: absolute;
    top: 10px;
    left: 8px;
    padding: 0px 10px;
    color: #ffffff;
    transition: 0.3s;
    pointer-events: none;
    font-weight: 600;
}

.profile--form .input:focus + .placeholder, .profile--form .input:not(:placeholder-shown) +.placeholder {
    top: -10px;
    background-color: white !important;
    border-radius: 20px;
    font-size: 11px;
}



.profile--form button:disabled {
    pointer-events: none;
    filter: opacity(0.5);
}

.swal-button {
    padding: 8px 20px;
    border-radius: 2px;
    background-color: #6fc784;
    font-size: 17px;
    border-radius: 25px;
    overflow: hidden;

  }

  .swal-button:not([disabled]):hover {
    background-color: #007bff;
}

.swal-button-container {
    margin: 5px;
    display: flex;
    position: relative;
    justify-content: center;
}


/*==========ANIMATION===========*/
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .profile--form h2 {
        font-size: 50px;
    }


}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .profile--form h2 {
        font-size: 50px;
    }

}
/*Desktop*/
@media screen and (min-width:813px){

}