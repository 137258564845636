.stories-customers .swiper-pagination-bullet {
  display: inline-block;
  width: 40px;
  height: 10px;
  margin: 0 9px;
  background: #00A1A9;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
}
.stories-customers p, .stories-customers h3 {
  color: white;
}
.stories-customers .swiper-slide {
  padding-bottom: 50px;
  text-align: left;
}

.stories-customers .item-storie {
  padding: 90px 0;
}

.stories-customers .swiper-pagination {
  text-align: left;
}

.stories-customers .container .item-storie:nth-child(even) {
  display: flex;
  flex-direction: row; /* Dirección por defecto */
}

.stories-customers .container .item-storie:nth-child(odd) {
  display: flex;
  flex-direction: row-reverse; /* Dirección intercalada */
}

  .logo-container {
    position: relative; /* Necesario para posicionar los elementos hijos */
  }

.light-effect {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00A1A9;
  filter: blur(180px);
  border-radius: 50%;
  position: absolute;
  mix-blend-mode: lighten;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}



/*Mobil*/
@media screen and (max-width:512px){
  .stories-customers .container .item-storie {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .stories-customers .item-storie {
    padding: 50px 0;
  }
  .logo-container {
    padding-bottom: 90px;
  }
}

/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
  .stories-customers .container .item-storie {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .stories-customers .item-storie {
    padding: 50px 0;
  }
  .logo-container {
    padding-bottom: 90px;
  }
}

/*Desktop*/
  @media screen and (min-width:813px){
      .profile--slider div .text-center {
          padding: 0 50px;    }
      .stories-customers .item-storie {
        padding: 90px 0;
      }
  }