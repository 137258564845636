.banner--careers {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url("img/careers/banner-careers.jpg") no-repeat scroll center bottom;
    background-size: cover;
    padding: 180px 0 120px;
  }



  /*Mobil*/
@media screen and (max-width:512px){
    .banner--careers h1 {
        font-size: 60px;
        line-height: 50px;
      }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .banner--careers h1 {
        font-size: 60px;
      }
}
/*Desktop*/
@media screen and (min-width:813px){

}