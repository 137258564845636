/*==========STYLES===========*/
.about-experience {
    background: url("img/about/fondo-nuestra-experiencia.jpg") no-repeat scroll center bottom;
    background-size: cover;
}

.about-experience ul li {
    color: white;
    font-size: 22px;
    font-weight: 500;
}

.about-experience h2::before {
    content: "";
    background: url("img/about/Figura-blanca.svg") no-repeat scroll right bottom;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    animation: rotate 3s infinite alternate;
}

.about-experience ul li::marker {
    color: #00FFFF;
    font-size: 30px;
}

.about-experience ul::before {
    content: "";
    background: url("img/about/Figura-aguamarina.svg") no-repeat scroll right bottom;
    position: absolute;
    width: 70px;
    height: 100%;
    top: 28%;
    animation: slideInleft 3s infinite alternate;

}

.experience-content {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.slideInLeft {
    -webkit-animation: slideInleft 3s infinite alternate;
    animation: slideInleft 3s infinite alternate;
}

.animation-rotateLeft {
    animation: slideInleft 5s, rotate 5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.animation-rotate {
    animation: rotate 5s infinite alternate;
}


/*==========ANIMATION===========*/

@keyframes slideInleft {
    0% {
        transform: translateX(80%)
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes rotate {
    100% {
        transform: rotate(-100deg);
    }
}

/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
    .about-experience h2::before {
        display: none;
    }

    .experience-content {
        margin-bottom: 4rem;
    }

    .experience-content h2 {
        font-size: 30px;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .f_sm_40 {
        font-size: 30px !important;
    }
}
/*Desktop*/
@media screen and (min-width:813px){

}