/*==========STYLES===========*/

.our--process-area {
  background: url("img/home/home-staff.webp") no-repeat scroll center bottom;
  background-size: cover;
  padding: 100px 0 70px;
}

.our--process-area .seo--title h1 {
    color: #246DFB;
    font-weight: 600;
}

.our--process-area .btn-folder:hover {
  color: #0e2455 ;
  background-color: #00FFF0;
}

.our--process-area .btn-folder  {
  color: #00FFF0; 
  padding: 15px 60px; 
  border-color: #00FFF0; 
  cursor: pointer;
  border: 2px solid #00FFF0;
}

/*==========ANIMATION===========*/

/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width: 512px) { 
    .our--process-area h2 {
      font-size: 35px;
    }

    .our--process-area p {
      font-size: 20px;
    }


}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
  .our--process {
    margin: 15px 0 !important;
  }
  .our--process {
    padding: 50px 20px;}
}
/*Desktop*/
@media screen and (min-width:821px){
}






  
  
  