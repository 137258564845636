



.benefits_item img {
  max-width: 100%;
}


  
  .benefits_item {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .img-icons img {
    max-width: 100%;
    width: 8%;
  }


  .benefits_item .team_content {
    border: 1px solid #9DB6BA;
    border-radius: 35px;
    background: #142735    ;
    padding: 15px 30px;
    transition: all 0.4s ease;
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .benefits_item .team_content:hover {
    background: #00d1caea;
  }


  /*==========ANIMATION===========*/
/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:350px){
  .banner--benefics .col-lg-8 h3 {
    font-size: 20px;
  }
  .benefits_item .team_content {
      height: 60px;
      }
}
@media (min-width: 351px) and (max-width:512px){
  .banner--benefics .col-lg-8 h3 {
    font-size: 20px;
  }
  .benefits_item .team_content {
    height: 60px;
    padding: 15px 25px ;
  }
  .col-bf-6 {
    flex: 0 0 auto;
    max-width: 50%;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .col-bf-6:nth-child(3n) {
    flex: 0 0 90%;
    max-width: 90%;
  }
  
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}



