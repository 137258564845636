.banner--blog {
  background: url("img/blog/banner-DreamBlog.webp") no-repeat scroll center bottom;
  background-size: cover;
  
}

.elements-banner-blog {
  position: relative;
  width: 100%;

}

.elements-banner-blog .figure1 {
  bottom: 5vh;
  left: 0;
  height: 200px;
  width: 200px;
  animation: rotate 5s infinite alternate;
}

.elements-banner-blog .figure2 {
  top: 20vh;
  left: 10vh;
  height: 200px;
  width: 200px;
  animation: showUp 5s infinite alternate;

}

.elements-banner-blog .figure3 {
  right: 0;
  top: 0;
  height: 400px;
  width: 15%;
  animation: showExtend 5s infinite alternate;
}

.elements-banner-blog .figure4 {
  bottom: 10vh;
  right: 20vh;
  height: 200px;
  width: 200px;
  animation: showUpFlash 5s infinite alternate;

}

.elements-banner-blog .figure5 {
  bottom: 0vh;
  right: 30vh;
  height: 100px;
  width: 100px;
  animation: moveInLeft 6s infinite alternate;
}

.elements-banner-blog .figure6 {
  top: 15vh;
  left: 30vh;
  height: 100px;
  width: 100px;
  animation: moveInRight 6s infinite alternate;
}




/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
  .banner--blog h1 {
    font-size: 40px;
    margin-bottom: 0;
  }

  .banner--blog {
    height: 250px;
  }


  .elements-banner-blog .figure1 {
    bottom: 3vh;
    left: 5vh;
    height: 50px;
    width: 50px;
  }

  .elements-banner-blog .figure2 {
    top: 5vh;
    left: 5vh;
    height: 40px;
    width: 40px;
  }

  .elements-banner-blog .figure3 {
    right: 0;
    top: 0;
  }
  
  .elements-banner-blog .figure4 {
    bottom: 5vh;
    right: 0;
    height: 50px;
    width: 50px;
  }

  .elements-banner-blog .figure5 {
    bottom: 0;
    right: 8vh;
    height: 20px;
    width: 20px;
  }
  
  .elements-banner-blog .figure6 {
    top: 6vh;
    left: 8vh;
    height: 20px;
    width: 20px;
  }
  
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
  .banner--blog {
    height: 400px;
  }

  .elements-banner-blog .figure1 {
    bottom: 3vh;
    left: 5vh;
    height: 50px;
    width: 50px;
  }

  .elements-banner-blog .figure2 {
    top: 5vh;
    left: 5vh;
    height: 60px;
    width: 60px;
  }

  .elements-banner-blog .figure3 {
    right: 0;
    top: 0;
  }
  
  .elements-banner-blog .figure4 {
    bottom: 5vh;
    right: 0;
    height: 80px;
    width: 80px;
  }

  .elements-banner-blog .figure5 {
    bottom: 0;
    right: 8vh;
    height: 50px;
    width: 50px;
  }
  
  .elements-banner-blog .figure6 {
    top: 6vh;
    left: 8vh;
    height: 50px;
    width: 50px;
  }

  .banner--blog h1 {
    font-size: 60px;
    margin-bottom: 0;
  }
}
/*Desktop*/
@media screen and (min-width:821px){
  .banner--blog {
    height: 560px;
  }
}


/*===ANIMATION===*/


@keyframes rotate {
  100% {
    -webkit-transform:rotate(-100deg);
    -ms-transform:rotate(-100deg);
    transform: rotate(-100deg);
  }
}


@keyframes showUp {
  0%{
      opacity:0;
      -webkit-transform:translate(0px, 20vh);
      -ms-transform:translate(0px, 20vh);
      transform:translate(0px, 20vh)
  }
  100%{
      -webkit-transform:translate(0,0);
      -ms-transform:translate(0,0);
      transform:translate(0,0)
  }
}

@keyframes showExtend {
  0%{
      opacity:0;
      -webkit-transform:scale(0.9);
      -ms-transform:scale(0.9);
      transform:scale(0.9);
  }
  100%{
      -webkit-transform:scale(1);
      -ms-transform:scale(1);
      transform:scale(1)
  }
}

@keyframes showUpFlash {
  0%{
      opacity:0;
      -webkit-transform:translateX(0px) translateY(50px);
      -ms-transform:translateX(0px) translateY(50px);
      transform:translateX(0px) translateY(50px);
  }
  50%{
    opacity:1;
    -webkit-transform:translateX(0px) translateY(0px);
    -ms-transform:translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
}
  100%{
    opacity:0;
    -webkit-transform:translateX(0px) translateY(50px);
    -ms-transform:translateX(0px) translateY(50px);
    transform:translateX(0px) translateY(50px);
}
}

@keyframes moveInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(-200px);
      -ms-transform: translateX(-200px);
      transform: translateX(-200px)

  }
}

@keyframes moveInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(200px);
      -ms-transform: translateX(200px);
      transform: translateX(200px)

  }
}