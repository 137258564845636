

.testimonial .swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  overflow: inherit;
}

  .testimonial--item {
    padding: 10px 25px;
  }
  
  .testimonial--item .content {
    background-color: white;
    box-shadow: 1px 1px 12px 5px rgb(1 1 0 / 10%);
    padding: 20px;
    position: relative;
    border-radius: 10px;
    border-left: 5px solid #afafaf;
  }
  
  .testimonial--item .content p {
    line-height : 20pt;
    letter-spacing: 0.7px;
  }

  .swiper-slide-active .testimonial--item .content {
    border-left: 5px solid #A35DE9;
  }
  .swiper-slide-active .testimonial-icon{
    color: #775CDF;
  }


  /*Mobil*/
  @media screen and (max-width:512px){
    .testimonial--item .content p {
      line-height: 16pt;
      letter-spacing: 0.7px;
      font-size: 15px !important;
    }

    .testimonial--box, .testimonial--item {
      width: 100% !important;
    }
    .testimonial .swiper-button-prev { left: 35%;}
    .testimonial .swiper-button-next { right: 35%;}

  }



/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}
  
  