.profile--banner {
    padding: 30px 0;
  }
  
  .profile--banner h1::after {
    content: "";
    background: url("img/iconos/fondo-home2.png") no-repeat scroll center bottom;
    background-size: cover;
    display: inline-block;
    width: 65px;
    height: 65px;
    z-index: 0;
    right: 0;
    top: 0;
  }

  /*Mobil*/
@media screen and (max-width:512px){
  .profile--banner h1 {
    font-size: 27px;
  }
}