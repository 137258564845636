
.footer--area {
  background-color: var(--background-color);
}

  .footer--area .footer--top {
    border-top: 0.1px solid #e9ecf36b;
    padding: 50px 0 20px;
  }

  .read--more-btn, .footer--widget .widget-wrap p a{
    color: var(--paragraph-text-color);
  }

  .footer--widget li .read--more-btn:hover:before {
    background: #004B8E !important;
  }
  
  .footer--widget li:last-child {
    margin-bottom: 0px;
  }
  
  .footer--top .footer--widget li a:hover , .footer--top .footer--widget .widget-wrap p a:hover,
  .footer--bottom li a:hover {
    color: #004B8E !important;
  }


  /*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
.footer--bottom .row, .footer--top .row {
    align-items: flex-start;
    padding: 0 30px;
    text-align: left !important;
}

.footer--bottom ul {
  text-align: left !important;
}

.footer--top .row div {
  margin-bottom: 10px;
}

.footer--widget {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){

}