.banner-home.video_area {
    position: relative;
    z-index: 1;
  }

 .banner-home .video_area iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
 .banner-home .video_content {
    position: relative;
  }

 .banner-home .video_info_no_img {
    background: rgba(0, 0, 0, 0.192) !important;
  }
  
  
 .banner-home .video_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 10px;

    background: -webkit-linear-gradient(40deg, rgba(58, 43, 220, 0.9) 0%, rgba(23, 199, 189, 0.3) 100%), url("img/home/video_bg.png") no-repeat;
    background: -o-linear-gradient(40deg, rgba(58, 43, 220, 0.3) 0%, rgba(23, 199, 189, 0.3) 100%), url("img/home/video_bg.png") no-repeat;
    background: linear-gradient(50deg, rgba(58, 43, 220, 0.3) 0%, rgba(23, 199, 189, 0.3) 100%), url("img/home/video_bg.png") no-repeat;
    background-size: cover;
    background-position-x: center;

    -webkit-box-shadow: 0px 50px 70px 0px rgba(0, 11, 40, 0.24);
    box-shadow: 0px 50px 70px 0px rgba(91, 91, 91, 0.24);

    max-width: 80%;
    height: 500px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

 .banner-home .video_info iframe {
    border-radius: 10px;
  }

  
 .banner-home .video_icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffffc2;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
    box-shadow: 0px 20px 40px 0px rgba(1, 16, 58, 0.14);
    text-align: center;
    line-height: 85px;
    font-size: 30px;
    position: relative;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    display: inline-block;
    cursor: pointer;
  }
  
 .banner-home .video_icon:before, .banner-home .video_icon:after {
    content: "";
    width: 100%;
    height: 100%;
    border: 0.2px solid #fff;
    border-radius: 50%;
    opacity: 0.10;
    position: absolute;
    left: 0;
  }
  
 .banner-home .video_icon:before {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
  
 .banner-home .video_icon:after {
    -webkit-transform: scale(3.8);
    -ms-transform: scale(3.8);
    transform: scale(3.8);
  }
  
 .banner-home .video_info i {
    color: #0f0f0f;
    text-shadow: 0px 6px 25px rgba(16, 147, 140, 0.9);
    position: relative;

  }
  
 .banner-home .video_icon i:after {
    width: 300%;
    height: 300%;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.5);
    content: "";
    -webkit-animation: pulso 2s infinite;
    -webkit-transform: scale(8);
    -ms-transform: scale(8);
    transform: scale(8);
    animation: pulso 2s infinite;
  }
  
 .banner-home .video_icon:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
  }


  /*--Mobile--*/
  @media screen and (max-width: 512px) {
     .banner-home .video_info iframe{
        width: 100%;
        height: 315px;
      }


     .banner-home .video_info {
        width: 75%;
        height: 315px;
      }
  }
/*--Tablet--*/
  @media (min-width: 513px) and (max-width:821px){
   .banner-home .video_info iframe{
      width: 47vh;
      height: 312px;
    }

   .banner-home .video_info {
      width: 100%;
      height: 315px;
    }
  }


  /*============ keyframes animation  =============*/
@-webkit-keyframes pulso {
    0% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(2);
      transform: translate(-50%, -50%) scale(2);
    }
  }
  
  @keyframes pulso {
    0% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(2);
      transform: translate(-50%, -50%) scale(2);
    }
  }