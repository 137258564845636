
.our_tech_talent h2  {
    background: linear-gradient(90deg, rgba(0,244,232,1) 0%, rgba(191,255,255,1) 56%);
    background: -webkit-linear-gradient(90deg, rgba(0,244,232,1) 0%, rgba(191,255,255,1) 56%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;  
    padding-bottom: 25px;
}

.container-grad {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 15px;
}

.container-grad p {
    font-weight: 600;
    font-size: 22px;
}
.column {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
  }

  .col-one{ grid-template-rows: 2fr 3fr; }
  .col-two { grid-template-rows: 1fr 1fr; }
  .col-three { grid-template-rows: 3fr 2fr; }

  .col-one .fila-one {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;
  }

  .col-item:hover {
    transform: scale(1.02);
}

.col-item:hover img {
      transform: rotate(2deg);
   
  }
  
  .col-item {
    padding: 20px;
    color: white;
    background: linear-gradient(90deg, rgba(48,81,255,1) 0%, rgba(0,219,255,1) 100%);
    border-radius : 25px;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;

  }




/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
.our_tech_talent h2 {
    font-size: 25px !important;
    padding-bottom: 20px;
}
.container-grad p {
    font-size: 18px;
}
.container-grad {
    grid-template-columns: 1fr; 
    padding: 0 15px;
}
.column {
    grid-template-rows: repeat(2, auto); 
}
.col-three {
    grid-template-columns: repeat(2, auto);
  }

}
/*Tablet*/
@media (min-width: 513px) and (max-width:1025px){
    .our_tech_talent h2 {
        
        padding: 0 20px;
    }
    .container-grad {
        grid-template-columns: 1fr; 
        padding: 0 15px;
    }
    .column {
        grid-template-rows: repeat(2, auto); 
        gap: 15px;
    }
    .col-three, .col-two {
        grid-template-columns: 1fr 1fr;
        row-gap: 0px;
      }
}
/*Desktop*/
@media screen and (min-width:1025px){
    .container-grad {
        padding: 0 50px;
    }


}



/*------------------------*/
.col-item.color-1 {
background: rgb(211,0,108);
background: -moz-linear-gradient(90deg, rgba(211,0,108,1) 0%, rgba(255,80,247,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(211,0,108,1) 0%, rgba(255,80,247,1) 100%);
background: linear-gradient(90deg, rgba(211,0,108,1) 0%, rgba(255,80,247,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d3006c",endColorstr="#ff50f7",GradientType=1);
}
.col-item.color-2 {
background: rgb(255,120,0);
background: -moz-linear-gradient(90deg, rgba(255,120,0,1) 0%, rgba(215,219,0,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(255,120,0,1) 0%, rgba(215,219,0,1) 100%);
background: linear-gradient(90deg, rgba(255,120,0,1) 0%, rgba(215,219,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff7800",endColorstr="#d7db00",GradientType=1);
}
.col-item.color-3 {
background: rgb(48,81,255);
background: -moz-linear-gradient(90deg, rgba(48,81,255,1) 0%, rgba(0,219,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(48,81,255,1) 0%, rgba(0,219,255,1) 100%);
background: linear-gradient(90deg, rgba(48,81,255,1) 0%, rgba(0,219,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3051ff",endColorstr="#00dbff",GradientType=1);
}
.col-item.color-6 {
background: rgb(183,48,255);
background: -moz-linear-gradient(90deg, rgba(183,48,255,1) 0%, rgba(50,0,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(183,48,255,1) 0%, rgba(50,0,255,1) 100%);
background: linear-gradient(90deg, rgba(183,48,255,1) 0%, rgba(50,0,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b730ff",endColorstr="#3200ff",GradientType=1);
}
.col-item.color-5 {
background: rgb(71,18,204);
background: -moz-linear-gradient(90deg, rgba(71,18,204,1) 0%, rgba(69,98,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(71,18,204,1) 0%, rgba(69,98,255,1) 100%);
background: linear-gradient(90deg, rgba(71,18,204,1) 0%, rgba(69,98,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4712cc",endColorstr="#4562ff",GradientType=1);
}
.col-item.color-7 {
background: rgb(60,196,211);
background: -moz-linear-gradient(90deg, rgba(60,196,211,1) 0%, rgba(64,255,21,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(60,196,211,1) 0%, rgba(64,255,21,1) 100%);
background: linear-gradient(90deg, rgba(60,196,211,1) 0%, rgba(64,255,21,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3cc4d3",endColorstr="#40ff15",GradientType=1);
}
.col-item.color-4 {
background: rgb(61,159,255);
background: -moz-linear-gradient(47deg, rgba(61,159,255,1) 0%, rgba(13,255,189,1) 100%);
background: -webkit-linear-gradient(47deg, rgba(61,159,255,1) 0%, rgba(13,255,189,1) 100%);
background: linear-gradient(47deg, rgba(61,159,255,1) 0%, rgba(13,255,189,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3d9fff",endColorstr="#0dffbd",GradientType=1);
}