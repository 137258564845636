.profile--slider .swiper-pagination-bullet {
    display: inline-block;
    width: 40px;
    height: 10px;
    margin: 0 9px;
    background: #333;
    border-radius: 4px;
    cursor: pointer;
}

.profile--slider .swiper-slide {
    padding-bottom: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}



  /*Mobil*/
  @media screen and (max-width:512px){

  }

  /*Desktop*/
    @media screen and (min-width:813px){
        .profile--slider div .text-center {
            padding: 0 50px;    }
    }