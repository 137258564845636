.btn-nav-swiper .swiper-button-next,
.btn-nav-swiper .swiper-button-prev {
  background-color: transparent;
  color: var(--title-text-color);
  font-size: 4rem;
  cursor: pointer;
  top: 90%;
  width: 35px;
  height: 35px;
}

.btn-nav-swiper .swiper-button-next:after,
.btn-nav-swiper .swiper-button-prev:after {
  content: none; 
}


  /*Mobil*/
  @media screen and (max-width:512px){
.btn-nav-swiper .swiper-button-prev { left: 35%;}
.btn-nav-swiper .swiper-button-next { right: 35%;}
}

/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:813px){
  .btn-nav-swiper .swiper-button-prev { left: 45%;}
  .btn-nav-swiper .swiper-button-next { right: 45%;}
}