.bg-dreamcoders {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("img/dreamcoders/estrellas.png") no-repeat scroll center bottom;
    background-size: cover;
}




@media screen and (max-width: 512px) {
    .bg-dreamcoders h1 {
        font-size: 40px;
        line-height: 1.1;
    }
    .dreamcoders figure img {
        height: 600px;
    }
    .bg-dreamcoders p {
        font-size: 25px;
    }
}