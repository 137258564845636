.banner-stories .banner--img img {
    width: 500px;
    margin-bottom: 35px;
    position: relative;
    z-index: 1;
}

.btn--stories {
    border: 1px solid #9DB6BA;
    border-radius: 35px;
    background: #142735;
    padding: 13px 30px;
    transition: all 0.4s ease;
    height: auto;

}


  /*---Responsive---*/
/*Mobil*/
@media screen and (max-width:512px){
    .btn--stories {
        margin: 40px 0 0px;
    }
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){
    .btn--stories {
        margin: 40px 0 30px;
    }
}
/*Desktop*/
@media screen and (min-width:813px){
    .btn--stories {
        margin: 40px 0;
    }
}