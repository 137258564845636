.searchField {
  padding: 0 0 30px;
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
}
.searchField input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 30px 0;
  padding-left: 2.5rem;
  border: 2px solid #e5e7eb;
  border-radius: 30px;
  outline: none;
  background-color: #f3f3f4;
  color: #202023;
  transition: .3s ease;
 }
  
.searchField input::placeholder {
  color: #9e9ea7;
 }
 
.searchField input:focus, input:hover {
  outline: none;
  border-color: #246cfb;
  background-color: #fff;
  box-shadow: 0 0 10px 4px #246cfb66;
 }

 .searchField:focus .icon, .searchField:hover .icon{
  fill: #246cfb;
 }
 
 .searchField .icon {
  position: absolute;
  left: 1.1rem;
  fill: #9e9ea7;
  width: 1.1rem;
  height: 1.1rem;
 }

 .post-list .post--content h2 {
  font-size: 20px !important;
}