



.profile--role h2 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}





/*==========RESPONSIVE===========*/
/*Mobil*/
@media screen and (max-width:512px){
.benefics--tab  h2 {
  font-size: 20px;
}
}
/*Tablet*/
@media (min-width: 513px) and (max-width:821px){

}
/*Desktop*/
@media screen and (min-width:821px){

}